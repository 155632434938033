<template>
  <span class="module-view">
    <!-- Breadcrumb ----------->
    <div class="module-view__page-titles row page-titles" v-if="disableBreadcrumb === false">
      <slot v-if="hasNavigationSlot" name="navigation"></slot>
      <div v-else class="col-md-6 col-6 align-self-center">
        <h3 class="module-view__title text-themecolor m-b-0 m-t-0">{{ $t('route.' + $route.name) }}</h3>
      </div>

      <div class="col-md-6 col-6 align-self-center">
        <div class="d-flex justify-content-end">
          <slot name="buttons"></slot>
        </div>
      </div>
    </div>

    <!-- Optional Header Slot -->
    <div v-if="$slots.header" class="module-view__header">
      <slot name="header"></slot>
    </div>
    <!-- Body --------------->
    <div class="row">
      <div class="col-12">
        <slot name="body"></slot>
      </div>
    </div>
  </span>
</template>

<script>
export default {
  name: 'ModuleView',
  props: {
    disableBreadcrumb: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hasNavigationSlot () {
      return !!this.$slots.navigation
    }
  }
}
</script>

<style lang="scss" scoped>
  .module-view {
    &__title {
      opacity: 0;
      pointer-events: none;
    }
    &__page-titles {
      background: transparent;
      padding: 10px;
      margin: 0 -20px 20px;
      .app.article_list &,
      // .app.hp_topic_offer_list &,
      .contentBlockItem_list & {
        display: none;
      }
    }
  }
</style>
